@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/


@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
  }

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
  /* color: #141414;
  background-color: #f2efff; */

  /* color: #ffffff; */
  background-color: #0f0622;
}



html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* iOS is known for messing up with forms */
input,
textarea {
  /*-webkit-appearance: none;*/
}

.grecaptcha-badge {
  visibility: hidden;
}

.splide__arrow--next {
  margin-right: -1.4rem;
}

.splide__arrow--prev {
  margin-left: -1.4rem;
}

/* chatbot */
.prose > div > p:first-child {
  margin-top: 0;
}

.prose > div > p:last-child {
  margin-bottom: 0;
}

/* Main page animations */
@keyframes fadeIn {
  0% {
    /* opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% { */
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

.transition-hidden {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  opacity: 0;
  transform: translateY(20px);
}

.transition-visible {
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeOut {
  0% {
    /* opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    */
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-animation {
  animation: fadeIn 2.5s ease-in-out forwards;
}

.fade-out-animation {
  animation: fadeOut 2.5s ease-in-out backwards;
}

.translate-x-10 {
  transform: translateX(30px);
}

.-translate-x-10 {
  transform: translateX(-20px);
}

/* Mani page animation ends */

/* About Comp */

.img-area {
  position: relative;
  height: 300px;
  width: 600px;
}

.img-area img {
  position: absolute;
  transition: opacity 1s ease-in-out;
}

.img-area img.img-back {
  opacity: 0; /* Initially hide circle2 */
}

.img-area:hover img.img-back {
  opacity: 1; /* Reveal circle2 on hover */
}

.img-area img.img-front {
  opacity: 1;
  cursor: pointer;
}

.img-area img.img-front:hover {
  opacity: 0;
  cursor: pointer;
}

/* About Comp ends */

/* Scalloped border [The curly thing] */

.scallopNav {
  /* z-index: 100; */
}
.scallopNav::after {
  z-index: 100;
  position: fixed;
  top: 11%;
  left: 0px;
  width: 100%;
  content: ' ';
  background: radial-gradient(
    circle at 50% 0%,
    black 25%,
    transparent 26%,
    transparent 40%
  );
  background-size: 20px 40px;
  height: 25px;
  background-repeat: repeat-x;
}

.scallopBottom {
  position: relative;
  background: black;
  padding: 0px;
}
.scallopBottom::after {
  position: absolute;
  bottom: 60px;
  left: 0px;
  width: 100%;
  content: ' ';
  background: radial-gradient(
    circle at 50% 0%,
    black 25%,
    transparent 26%,
    transparent 40%
  );
  /* background-color: gray; */
  background-size: 20px 40px;
  height: 25px;
  background-repeat: repeat-x;
}

.scallopTop {
  position: relative;
  background: black;
  padding: 0px;
}
.scallopTop::before {
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  content: ' ';
  transform: rotate(180deg);
  background: radial-gradient(
    circle at 50% 0%,
    black 25%,
    transparent 26%,
    transparent 40%
  );
  /* background-color: gray; */
  background-size: 20px 40px;
  height: 25px;
  background-repeat: repeat-x;
}

/* Scallop ends */

/* Audio Component */

.kg-audio-card {
  display: flex;
  width: 100%;
  min-height: 96px;
  border-radius: 15px;
  border: 1px solid gray;
  background-color: #602ae1;
}

.kg-audio-thumbnail {
  width: 80px !important;
  margin: 8px !important;
  border-radius: 5px !important;
}

.kg-audio-hide {
  display: none;
}

.kg-audio-player-container {
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 8px 12px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

  background-color: #0f0622;
  color: #fff;
}

.kg-audio-title {
  width: 100%;
  font-size: 1.15em;
  font-weight: 900;
  line-height: 1.15em;
  /* margin-top: 10px; */
}

.kg-audio-player {
  padding: 14px 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.kg-audio-play-icon {
  width: 15px;
}

.kg-audio-play-icon svg path {
  fill: #ffffff; /* White color */
}

.kg-audio-pause-icon {
  width: 15px;
}

.kg-audio-pause-icon svg rect {
  fill: #ffffff; /* White color */
}

.kg-audio-volume-slider {
  display: none;
}

.kg-audio-playback-rate {
  display: none;
}

.kg-audio-current-time {
  margin: 0px 0px 0px 5px;
}

.kg-audio-seek-slider {
  margin-left: 10px;
  width: 80%;
  height: 8px;
  -webkit-appearance: none;
  appearance: none;
  background: #362e48;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.kg-audio-seek-slider:hover {
  opacity: 1;
}

.kg-audio-seek-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.kg-audio-seek-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #602ae1;
  cursor: pointer;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #7356e8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Audio Comp ends */

/* For Next Images */

/* For Arrows in SLider */

/*  */

/* Styles for out-of-focus slides */
.splide__slide:not(.is-active) {
  opacity: 0.8;
}

.splide__arrow__none {
  display: none !important;
}

/*  */
.custom-splide-arrows .splide__arrow {
  background-color: white; /* Change to your desired background color */
  width: 50px; /* Change to your desired width */
  height: 50px; /* Change to your desired height */
  position: absolute;
  top: 50%; /* Center the arrows vertically */
  transform: translateY(-50%);
  z-index: 10; /* Ensure the arrows are above other elements */
}

.custom-splide-arrows .splide__arrow--prev {
  left: 25%; /* Adjust the left position */
}

.custom-splide-arrows .splide__arrow--next {
  right: 25%; /* Adjust the right position */
}

@media (max-width: 768px) {
  .custom-splide-arrows .splide__arrow--prev {
    left: 10%; /* Adjust the left position for smaller screens */
  }

  .custom-splide-arrows .splide__arrow--next {
    right: 10%; /* Adjust the right position for smaller screens */
  }
}

.custom-splide-arrows .splide__arrow::before {
  color: black; /* Change to your desired arrow color */
  font-size: 24px; /* Adjust the size of the arrow icon */
}

/* Pr section ka alag */

.custom-splide-arrows-pr-section .splide__arrow {
  background-color: white; /* Change to your desired background color */
  width: 50px; /* Change to your desired width */
  height: 50px; /* Change to your desired height */
  position: absolute;
  top: 50%; /* Center the arrows vertically */
  transform: translateY(-50%);
  z-index: 10; /* Ensure the arrows are above other elements */
}

.custom-splide-arrows-pr-section .splide__arrow--prev {
  left: 30%; /* Adjust the left position */
}

.custom-splide-arrows-pr-section .splide__arrow--next {
  right: 30%; /* Adjust the right position */
}

@media (max-width: 1500px) {
  .custom-splide-arrows-pr-section .splide__arrow--prev {
    left: 8%;
  }

  .custom-splide-arrows-pr-section .splide__arrow--next {
    right: 8%;
  }
}

@media (max-width: 768px) {
  .custom-splide-arrows-pr-section .splide__arrow--prev {
    left: 10%;
  }

  .custom-splide-arrows-pr-section .splide__arrow--next {
    right: 10%;
  }
}

.custom-splide-arrows-pr-section .splide__arrow::before {
  color: black; /* Change to your desired arrow color */
  font-size: 24px; /* Adjust the size of the arrow icon */
}

/* SuperSoul */

@keyframes spin-slow {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.animate-spin-slow {
    animation: spin-slow 20s linear infinite;
}